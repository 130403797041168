import { Query } from "@lookiero/messaging.js";

export type SearchOrdersByCriteriaParameters = {
  readonly number?: string;
  readonly family?: string;
  readonly brand?: string;
  readonly season?: string;
  readonly placedOn?: string;
  readonly status?: string;
  readonly providerProductReference?: string;
  readonly providerOrderReference?: string;
  readonly page: number;
  readonly perPage: number;
  readonly orderType?: "ASC" | "DESC";
};

class SearchOrdersByCriteria extends Query {
  public readonly number?: string;
  public readonly family?: string;
  public readonly brand?: string;
  public readonly season?: string;
  public readonly placedOn?: string;
  public readonly status?: string;
  public readonly providerProductReference?: string;
  public readonly providerOrderReference?: string;
  public readonly page: number;
  public readonly perPage: number;
  public readonly orderType?: "ASC" | "DESC";

  public constructor({
    number,
    family,
    brand,
    season,
    placedOn,
    status,
    providerProductReference,
    providerOrderReference,
    page = 1,
    perPage,
    orderType,
  }: SearchOrdersByCriteriaParameters) {
    super();
    this.number = number;
    this.family = family;
    this.brand = brand;
    this.season = season;
    this.placedOn = placedOn;
    this.status = status;
    this.providerProductReference = providerProductReference;
    this.providerOrderReference = providerOrderReference;
    this.page = page;
    this.perPage = perPage;
    this.orderType = orderType;
  }

  public messageName(): string {
    return "SearchOrdersByCriteria";
  }
}

export default SearchOrdersByCriteria;
