import { AggregateRoot } from "@lookiero/messaging.js";
import LabelPrinted from "./LabelPrinted";

export interface ProductVariantPrint {
  readonly barcode: string;
  readonly quantity: string;
}

class Label extends AggregateRoot {
  public productVariants: ProductVariantPrint[];
  public printerLine: number;

  public constructor(productVariants: ProductVariantPrint[], printerLine: number) {
    super();
    this.productVariants = productVariants;
    this.printerLine = printerLine;
  }

  public static print(productVariants: ProductVariantPrint[], printerLine: number): Label {
    const instance = new Label(productVariants, printerLine);
    instance.record(new LabelPrinted(productVariants, printerLine));

    return instance;
  }
}

export default Label;
