import { EventBus } from "@lookiero/messaging.js";
import User from "@src/core/projection/user/model/User";
import UserView from "@src/core/projection/user/model/UserView";
import StorageClient from "@src/shared/delivery/StorageClient";
import { jwtDecode } from "jwt-decode";
import { UserJwtPayload } from "../../../domain/user/model/HttpAuthenticator";

class InMemoryUserView implements UserView {
  private eventBus!: EventBus;

  private static readonly USER_KEY = "user";

  private readonly userStorageClient: StorageClient;

  constructor(storage: StorageClient) {
    this.userStorageClient = storage;
  }

  public async getCurrentUser(): Promise<User> {
    const currentUser = await this.userStorageClient.get(InMemoryUserView.USER_KEY);
    const decodedToken = jwtDecode<UserJwtPayload>(currentUser.authToken);

    const roles = decodedToken.roles.split(",");

    currentUser.roles = roles;

    return currentUser as User;
  }

  public async isUserLoggedIn(): Promise<boolean> {
    return this.userStorageClient.has(InMemoryUserView.USER_KEY);
  }
}

export default InMemoryUserView;
