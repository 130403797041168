import { DomainEvent } from "@lookiero/messaging.js";
import { ProductVariantPrint } from "./Label";

class LabelPrinted extends DomainEvent {
  public productVariants: ProductVariantPrint[];
  public printerLine: number;

  public constructor(productVariants: ProductVariantPrint[], printerLine: number) {
    super();
    this.productVariants = productVariants;
    this.printerLine = printerLine;
  }

  public messageName(): string {
    return "LabelPrinted";
  }
}

export default LabelPrinted;
