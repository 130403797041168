import { AggregateRoot } from "@lookiero/messaging.js";
import ProductEdited from "./ProductEdited";
import { Prices, SellingPrices } from "../../../projection/prices/model/Prices";

type ProductVariant = {
  readonly id: string;
  readonly quantity: number;
  readonly unitId: string;
  readonly colorId: string;
  readonly sizeId: string;
  readonly costPriceCurrency: string;
  readonly costPriceAmount: number;
};

type Feature = {
  readonly id: string;
  readonly values: {
    readonly id: string; // feature_value_id
    readonly qualifier?: {
      readonly id: string; // feature_id
      readonly values: { id: string }[]; // feature_value_id
    };
  }[];
};
class Product extends AggregateRoot {
  public id: string;
  public purchaseId: string;
  public orderLineId: string;
  public group: string;
  public providerProductReference: string;
  public providerOrderReference: string;
  public familyId: string;
  public manufacturingCountry: string;
  public title: string;
  public sellingPrices: SellingPrices;
  public printed: boolean;
  public medias: string[];
  public features: Feature[];
  public productVariants: ProductVariant[];
  public targetChannels: string[];

  public constructor(
    id: string,
    purchaseId: string,
    orderLineId: string,
    group: string,
    providerProductReference: string,
    providerOrderReference: string,
    familyId: string,
    manufacturingCountry: string,
    title: string,
    sellingPrices: SellingPrices,
    printed: boolean,
    medias: string[],
    features: Feature[],
    productVariants: ProductVariant[],
    targetChannels: string[],
  ) {
    super();

    this.id = id;
    this.purchaseId = purchaseId;
    this.orderLineId = orderLineId;
    this.group = group;
    this.providerProductReference = providerProductReference;
    this.providerOrderReference = providerOrderReference;
    this.familyId = familyId;
    this.manufacturingCountry = manufacturingCountry;
    this.title = title;
    this.sellingPrices = sellingPrices;
    this.printed = printed;
    this.medias = medias;
    this.features = features;
    this.productVariants = productVariants;
    this.targetChannels = targetChannels;
  }

  public edit(
    providerProductReference: string,
    title: string,
    manufacturingCountry: string,
    sellingPrices: SellingPrices,
    printed: boolean,
    features: Feature[],
    productVariants: ProductVariant[],
    targetChannels: string[],
  ): void {
    this.providerProductReference = providerProductReference;
    this.title = title;
    this.manufacturingCountry = manufacturingCountry;
    this.sellingPrices = sellingPrices;
    this.printed = printed;
    this.features = features;
    this.targetChannels = targetChannels;

    const cleanedVariants = productVariants?.filter(
      variant => Boolean(variant.quantity) && Boolean(variant.costPriceAmount),
    );

    if (!cleanedVariants || cleanedVariants.length === 0) {
      throw new Error("Un producto de una compra debe de tener al menos una variante");
    }

    this.productVariants = cleanedVariants;

    this.record(new ProductEdited({ productId: this.id, purchaseId: this.purchaseId }));
  }
}

export default Product;
export type { Feature, ProductVariant, Prices };
