import { convertDTOToSellingPrices } from "../../../../../shared/dataTransformer/prices";
import OrderLine from "../../../../domain/orderLine/model/OrderLine";
import { SellingPrice } from "../../../../projection/prices/model/Prices";

type OrderLineUnitResponseDto = {
  readonly id: string;
  readonly quantity: number;
  readonly color: { id: string };
  readonly size: { id: string };
};

type OrderLineResponseDto = {
  readonly order_id: string;
  readonly id: string;
  readonly provider_product_reference: string;
  readonly title: string;
  readonly delivery_estimation: string;
  readonly family: { id: string; name: string; category: string };
  readonly group: string;
  readonly printed: boolean;
  readonly cost_price_currency: string;
  readonly cost_price_amount: number;
  readonly target_channels: string[];
  readonly manufacturing_country: string;
  readonly units: OrderLineUnitResponseDto[];
  readonly neckline: string;
  readonly risk_level: number;
  readonly selling_prices: SellingPrice[];
};

const orderLineResponseDtoToOrderLine = (orderLine: OrderLineResponseDto): OrderLine => {
  const sellingPrices = convertDTOToSellingPrices(orderLine);

  return new OrderLine(
    orderLine.order_id,
    orderLine.id,
    orderLine.provider_product_reference,
    orderLine.title,
    orderLine.delivery_estimation ? new Date(orderLine.delivery_estimation) : null,
    orderLine.family.id,
    null,
    orderLine.printed,
    orderLine.cost_price_currency,
    orderLine.cost_price_amount,
    sellingPrices,
    orderLine.units.map((unit: OrderLineUnitResponseDto) => ({
      id: unit.id,
      quantity: unit.quantity,
      colorId: unit.color.id,
      sizeId: unit.size.id,
    })),
    null,
    null,
    orderLine.neckline,
    orderLine.target_channels,
    orderLine.risk_level,
    null,
  );
};
export type { OrderLineResponseDto };
export { orderLineResponseDtoToOrderLine };
