import HttpClient from "@src/shared/delivery/HttpClient";
import packageInfo from "../../../../../../package.json";
import Environment from "../../../../projection/environment/model/Environment";
import EnvironmentFetcher from "../../../../projection/environment/model/EnvironmentFetcher";
import Currency from "../../../../projection/currency/model/Currency";

type EnvironmentResponseDto = {
  readonly buying_back_url: string;
  readonly catalog_back_url: string;
  readonly buying_back_username: string;
  readonly buying_back_password: string;
  readonly fashion_labs_product_url: string;
  readonly authApi: string;
  readonly featureToggle: {
    readonly jwtLoginEnabled: boolean;
  };
  readonly convert_EUR_to_GBP: number;
  readonly convert_EUR_to_SEK: number;
  readonly convert_EUR_to_CHF: number;
  readonly sentry_project: string;
  readonly sentry_publicKey: string;
  readonly printer_product_variant: number;
};

class HttpEnvironmentFetcher implements EnvironmentFetcher {
  private client: HttpClient;
  private environment!: Environment;

  public constructor(client: HttpClient) {
    this.client = client;
  }

  public async fetch(): Promise<Environment> {
    if (!this.environment) {
      const response = await this.client.get(`/config.json?${packageInfo.version}`);
      const dto: EnvironmentResponseDto = await response.json();
      this.environment = {
        buyingBackUrl: dto.buying_back_url,
        catalogBackUrl: dto.catalog_back_url,
        fashionLabsProductUrl: dto.fashion_labs_product_url,
        authApi: dto.authApi,
        featureToggle: {
          jwtLoginEnabled: dto.featureToggle.jwtLoginEnabled,
        },
        convertRatios: {
          [Currency.EUR]: 1,
          [Currency.GBP]: dto.convert_EUR_to_GBP || 1,
          [Currency.SEK]: dto.convert_EUR_to_SEK || 1,
          [Currency.CHF]: dto.convert_EUR_to_CHF || 1,
        },
        sentryProject: dto.sentry_project,
        sentryPublicKey: dto.sentry_publicKey,
        printerProductVariant: dto.printer_product_variant,
      };
    }

    return this.environment;
  }
}

export default HttpEnvironmentFetcher;
