import { Command } from "@lookiero/messaging.js";

interface CompleteOrderParameters {
  readonly orderId: string;
  readonly providerId: string;
  readonly recommendationId: string;
  readonly b2b: boolean;
  readonly segment: string;
  readonly providerOrderReference: string;
}

class CompleteOrder extends Command {
  public readonly orderId: string;
  public readonly providerId: string;
  readonly recommendationId: string;
  public readonly b2b: boolean;
  public readonly segment: string;
  public readonly providerOrderReference: string;

  public constructor({
    orderId,
    providerId,
    recommendationId,
    b2b,
    segment,
    providerOrderReference,
  }: CompleteOrderParameters) {
    super();
    this.orderId = orderId;
    this.providerId = providerId;
    this.recommendationId = recommendationId;
    this.b2b = b2b;
    this.segment = segment;
    this.providerOrderReference = providerOrderReference;
  }

  public messageName(): string {
    return "CompleteOrder";
  }
}

export default CompleteOrder;
