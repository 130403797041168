import { Command } from "@lookiero/messaging.js";

interface PlaceOrderParameters {
  readonly orderId: string;
  readonly providerId?: string;
  readonly brandId: string;
  readonly recommendationId: string;
  readonly seasonId: string;
  readonly b2b: boolean;
  readonly segment: string;
  readonly providerOrderReference: string;
}

class PlaceOrder extends Command {
  public readonly orderId: string;
  public readonly providerId?: string;
  public readonly brandId: string;
  public readonly recommendationId: string;
  public readonly seasonId: string;
  public readonly b2b: boolean;
  public readonly segment: string;
  public readonly providerOrderReference: string;

  public constructor({
    orderId,
    providerId,
    brandId,
    recommendationId,
    seasonId,
    b2b,
    segment,
    providerOrderReference,
  }: PlaceOrderParameters) {
    super();
    this.orderId = orderId;
    this.providerId = providerId;
    this.seasonId = seasonId;
    this.brandId = brandId;
    this.recommendationId = recommendationId;
    this.b2b = b2b;
    this.segment = segment;
    this.providerOrderReference = providerOrderReference;
  }

  public messageName(): string {
    return "PlaceOrder";
  }
}

export default PlaceOrder;
