import { EventBus } from "@lookiero/messaging.js";
import LabelRepository from "../../../../domain/label/model/LabelRepository";
import Label from "../../../../domain/label/model/Label";
import HttpClient from "../../../../../shared/delivery/HttpClient";

class HttpLabelRepository implements LabelRepository {
  private httpClient: HttpClient;
  private eventBus!: EventBus;

  public constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  public async print(label: Label): Promise<void> {
    try {
      const body = {
        product_variants: label.productVariants,
        printer_line: label.printerLine,
      };
      const response = await this.httpClient.post("/print-product-variants-label", body);

      if (!response.ok) {
        const result = await response.json();
        throw result;
      }

      this.eventBus.publish(label.recordedEvents());
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}

export default HttpLabelRepository;
