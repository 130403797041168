import Product from "@src/core/projection/product/model/Product";
import { PricePropertiesInterface, SellingPrice } from "@src/core/projection/prices/model/Prices";
import { convertDTOToPrices, convertDTOToSellingPrices } from "@src/shared/dataTransformer/prices";

export type ProductDto = {
  readonly id: string;
  readonly purchase_id: string;
  readonly order_line_id: string;
  readonly group: string;
  readonly provider_product_reference: string;
  readonly provider_order_reference: string;
  readonly family_id: string;
  readonly title: string;
  readonly printed: boolean;
  readonly medias: { id: string; url: string }[];
  readonly manufacturing_country: string;
  readonly features: {
    readonly id: string;
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: { id: string }[]; // feature_value_id
      };
    }[];
  }[];
  readonly product_variants: {
    readonly id: string;
    readonly quantity: number;
    readonly unit_id: string;
    readonly color_id: string;
    readonly size_id: string;
    readonly cost_price_currency: string;
    readonly cost_price_amount: number;
  }[];
  readonly target_channels: { id: string; name: string }[];
  readonly selling_prices: SellingPrice[];
} & PricePropertiesInterface;

const productDtoToProductProjection = (productDto: ProductDto): Product => {
  const prices = convertDTOToPrices(productDto);
  const sellingPrices = convertDTOToSellingPrices(productDto);

  return {
    id: productDto.id,
    purchaseId: productDto.purchase_id,
    orderLineId: productDto.order_line_id,
    group: productDto.group,
    providerProductReference: productDto.provider_product_reference,
    providerOrderReference: productDto.provider_order_reference,
    familyId: productDto.family_id,
    title: productDto.title,
    prices,
    printed: productDto.printed,
    medias: productDto.medias.map(({ id }) => id) || [],
    features: productDto.features,
    manufacturingCountry: productDto.manufacturing_country,
    productVariants:
      productDto.product_variants?.map(variant => ({
        id: variant.id,
        unitId: variant.unit_id,
        quantity: variant.quantity,
        colorId: variant.color_id,
        sizeId: variant.size_id,
        costPriceCurrency: variant.cost_price_currency,
        costPriceAmount: variant.cost_price_amount,
      })) || [],
    targetChannels: productDto.target_channels?.map(({ id }) => id) || [],
    sellingPrices,
  };
};

export { productDtoToProductProjection };
