import { OrderCriteria, PagedOrderCriteria } from "../../../../projection/order/model/OrderView";

type OrderCountCriteriaDto = {
  readonly number?: string;
  readonly season?: string;
  readonly status?: string;
  readonly family_id?: string;
  readonly brand_id?: string;
  readonly placed_on?: string;
  readonly provider_product_reference?: string;
  readonly provider_order_reference?: string;
};

type OrderSearchCriteriaDto = OrderCountCriteriaDto & {
  readonly page: number;
  readonly per_page: number;
  readonly order_by: string;
  readonly order_type: "ASC" | "DESC";
};

const orderCountCriteriaDto = ({
  number,
  family,
  brand,
  season,
  placedOn,
  status,
  providerProductReference,
  providerOrderReference,
}: OrderCriteria): OrderCountCriteriaDto => ({
  number,
  season,
  status,
  family_id: family,
  brand_id: brand,
  placed_on: placedOn,
  provider_product_reference: providerProductReference,
  provider_order_reference: providerOrderReference,
});

const orderSearchCriteriaDto = ({
  number,
  family,
  brand,
  season,
  placedOn,
  status,
  providerProductReference,
  providerOrderReference,
  page,
  perPage,
  orderType,
}: PagedOrderCriteria): OrderSearchCriteriaDto => ({
  number,
  season,
  status,
  family_id: family,
  brand_id: brand,
  placed_on: placedOn,
  provider_product_reference: providerProductReference,
  provider_order_reference: providerOrderReference,
  page,
  per_page: perPage,
  order_by: "number",
  order_type: orderType || "DESC",
});

export { orderCountCriteriaDto, orderSearchCriteriaDto };
