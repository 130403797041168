import { QueryHandler } from "@lookiero/messaging.js";
import OrderView from "../../model/OrderView";
import SearchOrdersByCriteria from "./SearchOrdersByCriteria";
import OrderPreview from "../../model/OrderPreview";

class SearchOrdersByCriteriaHandler implements QueryHandler<SearchOrdersByCriteria> {
  private readonly orderView: OrderView;

  public constructor(orderView: OrderView) {
    this.orderView = orderView;
  }

  public async handle({
    number,
    family,
    brand,
    season,
    placedOn,
    status,
    providerProductReference,
    providerOrderReference,
    page,
    perPage,
    orderType,
  }: SearchOrdersByCriteria): Promise<OrderPreview[]> {
    return await this.orderView.searchByCriteria({
      number,
      family,
      brand,
      season,
      placedOn,
      status,
      providerProductReference,
      providerOrderReference,
      page,
      perPage,
      orderType,
    });
  }
}

export default SearchOrdersByCriteriaHandler;
