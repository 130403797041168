import { CommandHandler } from "@lookiero/messaging.js";
import PrintProductVariantsLabels from "./PrintProductVariantsLabels";
import Label from "../../model/Label";
import LabelRepository from "../../model/LabelRepository";

class PrintProductVariantsLabelsHandler implements CommandHandler<PrintProductVariantsLabels> {
  private repository: LabelRepository;

  public constructor(repository: LabelRepository) {
    this.repository = repository;
  }
  public execute({ productVariants, printerLine }: PrintProductVariantsLabels): Promise<void> {
    const label = Label.print(productVariants, printerLine);
    return this.repository.print(label);
  }
}

export default PrintProductVariantsLabelsHandler;
