/* eslint-disable @typescript-eslint/explicit-function-return-type */
import OrderLine from "../../../../domain/orderLine/model/OrderLine";
import OrderLineUnit from "../../../../domain/orderLine/model/OrderLineUnit";
import { toISO } from "../../../../../ui/componentLibrary/_intl/date";
import { SellingPrice } from "../../../../projection/prices/model/Prices";
import { convertSellingPricesToDTO } from "@src/shared/dataTransformer/prices";

type Features = {
  readonly id: string;
  readonly values: {
    readonly id: string; // feature_value_id
    readonly qualifier?: {
      readonly id: string; // feature_id
      readonly values: string[]; // feature_value_id
    };
  }[];
}[];

export type OrderLineFromReceptionRequestDTO = {
  readonly id: string;
  readonly order_id: string;
  readonly provider_product_reference: string | undefined;
  readonly title: string;
  readonly delivery_estimation: string | undefined;
  readonly family_id: string | undefined;
  readonly printed: boolean | null;
  readonly cost_price_currency: string | null;
  readonly cost_price_amount: number | null;
  readonly features: Features | null;
  readonly units:
    | {
        readonly id: string;
        readonly quantity: number;
        readonly color_id: string;
        readonly size_id: string;
      }[]
    | null;
  readonly target_channels: string[];
  readonly risk_level: number | null;
  readonly selling_prices: SellingPrice[];
};

const orderLineRequestFromReceptionDtoFromOrderLine = (orderLine: OrderLine): OrderLineFromReceptionRequestDTO => {
  const sellingPrices = convertSellingPricesToDTO(orderLine.sellingPrices);

  return {
    id: orderLine.id,
    order_id: orderLine.orderId,
    provider_product_reference:
      orderLine.providerProductReference !== "" ? orderLine.providerProductReference : undefined,
    title: orderLine.title,
    delivery_estimation: orderLine.deliveryEstimation ? toISO(orderLine.deliveryEstimation) : undefined,
    family_id: orderLine.familyId || undefined,
    printed: Boolean(orderLine.printed),
    cost_price_currency: orderLine.costPriceCurrency,
    cost_price_amount: orderLine.costPriceAmount,
    selling_prices: sellingPrices,
    features: orderLine.features
      ? orderLine.features.map(feature => ({
          ...feature,
          values: feature.values.map(value => ({
            ...value,
            qualifier: value.qualifier
              ? { ...value.qualifier, values: value.qualifier?.values.map(v => v.id) }
              : undefined,
          })),
        }))
      : null,
    units: orderLine.units
      ? orderLine.units.map((unit: OrderLineUnit) => ({
          id: unit.id,
          quantity: parseInt(`${unit.quantity}`, 10),
          color_id: unit.colorId,
          size_id: unit.sizeId,
        }))
      : null,
    target_channels: orderLine.targetChannels || [],
    risk_level: orderLine.riskLevel,
  };
};

export type OrderLineFromBuyingAssistantRequestDTO = {
  readonly id: string;
  readonly order_id: string;
  readonly family_id: string | null;
  readonly provider_product_reference: string | undefined;
  readonly medias: string[] | null;
  readonly colors: string[] | null;
  readonly features: Features | null;
  readonly neckline: string | null;
  readonly target_channels: string[];
  readonly risk_level: number | null;
};

const orderLineRequestFromBuyingAssistantDtoFromOrderLine = (
  orderLine: OrderLine,
): OrderLineFromBuyingAssistantRequestDTO => ({
  id: orderLine.id,
  order_id: orderLine.orderId,
  family_id: orderLine.familyId,
  provider_product_reference:
    orderLine.providerProductReference !== "" ? orderLine.providerProductReference : undefined,
  medias: orderLine.medias,
  colors: orderLine.colors,
  features: orderLine.features
    ? orderLine.features.map(feature => ({
        ...feature,
        values: feature.values.map(value => ({
          ...value,
          qualifier: value.qualifier
            ? { ...value.qualifier, values: value.qualifier?.values.map(v => v.id) }
            : undefined,
        })),
      }))
    : null,
  neckline: orderLine.neckline,
  target_channels: orderLine.targetChannels || [],
  risk_level: orderLine.riskLevel,
});

export { orderLineRequestFromReceptionDtoFromOrderLine, orderLineRequestFromBuyingAssistantDtoFromOrderLine };
