import Product from "@src/core/domain/product/model/Product";
import { SellingPrice } from "../../../../projection/prices/model/Prices";
import { convertDTOToSellingPrices } from "../../../../../shared/dataTransformer/prices";

export type ProductDto = {
  readonly id: string;
  readonly purchase_id: string;
  readonly order_line_id: string;
  readonly group: string;
  readonly provider_product_reference: string;
  readonly provider_order_reference: string;
  readonly family_id: string;
  readonly manufacturing_country: string;
  readonly title: string;
  readonly printed: boolean;
  readonly medias: { id: string; url: string }[];
  readonly target_channels: string[];
  readonly features: {
    readonly id: string;
    readonly values: {
      readonly id: string; // feature_value_id
      readonly qualifier?: {
        readonly id: string; // feature_id
        readonly values: { id: string }[]; // feature_value_id
      };
    }[];
  }[];
  readonly variants: {
    readonly id: string;
    readonly quantity: number;
    readonly unit_id: string;
    readonly color_id: string;
    readonly size_id: string;
    readonly cost_price_currency: string;
    readonly cost_price_amount: number;
  }[];
  readonly selling_prices: SellingPrice[];
};

export const productDtoToProduct = (productDto: ProductDto): Product => {
  const sellingPrices = convertDTOToSellingPrices(productDto);

  return new Product(
    productDto.id,
    productDto.purchase_id,
    productDto.order_line_id,
    productDto.group,
    productDto.provider_product_reference,
    productDto.provider_order_reference,
    productDto.family_id,
    productDto.manufacturing_country,
    productDto.title,
    sellingPrices,
    productDto.printed,
    productDto.medias?.map(({ id }) => id) || [],
    productDto.features,
    productDto.variants?.map(variant => ({
      id: variant.id,
      quantity: variant.quantity,
      unitId: variant.unit_id,
      colorId: variant.color_id,
      sizeId: variant.size_id,
      costPriceCurrency: variant.cost_price_currency,
      costPriceAmount: variant.cost_price_amount,
    })) || [],
    productDto.target_channels,
  );
};
