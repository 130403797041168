import { Command } from "@lookiero/messaging.js";
import { ProductVariantPrint } from "../../model/Label";

class PrintProductVariantsLabels extends Command {
  public readonly productVariants: ProductVariantPrint[];
  public readonly printerLine: number;

  public constructor(productVariants: ProductVariantPrint[], printerLine: number) {
    super();
    this.productVariants = productVariants;
    this.printerLine = printerLine;
  }

  public messageName(): string {
    return "PrintProductVariantsLabels";
  }
}

export default PrintProductVariantsLabels;
