import { CommandBus, ProcessManager } from "@lookiero/messaging.js";
import CreateNotification from "@src/core/domain/notification/command/createNotification/CreateNotification";
import NotificationLevel from "@src/core/domain/notification/model/NotificationLevel";
import LabelPrinted from "../../domain/label/model/LabelPrinted";

class CreateNotificationWhenLabelPrinted implements ProcessManager<LabelPrinted> {
  private readonly commandBus!: CommandBus;

  public async process(): Promise<void> {
    this.commandBus.dispatch(
      new CreateNotification({
        level: NotificationLevel.SUCCESS,
        content: `Las etiquetas se han impreso correctamente`,
      }),
    );
  }
}

export default CreateNotificationWhenLabelPrinted;
